@import url('https://fonts.googleapis.com/css2?family=Sen&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-webkit-scrollbar{
    width: 0;
    height: 0;
} */

html * {
    font-family: sen;
}

.PhoneInput input{
    @apply p-2 border bg-gray-100
}